/* EVENTS */
export const CAMPER_AVAILABITY_CHECK_EVENT = 'camper_availability_check'
export const CAMPER_SEE_MORE_PICTURES_EVENT = 'camper_see_more_pictures'
export const CAMPER_PRICING_DETAILS_EVENT = 'camper_pricing_details'
export const CAMPER_REQUEST_DETAILS_EVENT = 'camper_request_details'
export const CAMPER_BOOKING_REQUEST_EVENT = 'booking_request'

/* PARAMS */
export const CAMPER_BOOKING_REQUEST_TRIP_REASON_PARAM = 'trip_reason'
export const CAMPER_BOOKING_REQUEST_TRAVELLERS_PARAM = 'number_of_travellers'
export const CAMPER_BOOKING_REQUEST_COUNTRIES_PARAM = 'countries_visited'
export const CAMPER_BOOKING_REQUEST_HAS_MESSAGE_PARAM = 'has_added_message'
export const CAMPER_BOOKING_REQUEST_FEES = 'fees'
